"use client";

import { useContext, useState } from "react";
import { useRouter } from "next/navigation";
import { Box, Button } from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import "monday-ui-react-core/tokens";
import { AppContext } from "@/app/context";
import { saveUser } from "@/app/api/user";
import { spSignIn } from "@/app/_sharepoint";
import { showErrorNotification } from "@/app/_util/notificator";
import { useMsal } from "@azure/msal-react";


export default function SpAuth() {
    const router = useRouter();
    const [isToast, setIsToast] = useState(false);
    const { state, setState } = useContext(AppContext);
    const { userId, mondayToken, theme } = state;
    const { instance: msalInstance } = useMsal();


    async function handleAuthentication() {
        try {
            const auth = await spSignIn(userId, mondayToken, msalInstance);
            if (!auth) {
                console.error("SharePoint login error", auth);
                showErrorNotification("SharePoint login error");
                handleError();
                return null;
            }
            localStorage.setItem("accessToken", auth.accessToken);

            setState({ ...state, spUser: auth.account?.username, spToken: auth.accessToken | "" });
            const saveResult = await saveUser(userId, auth.account.username, auth.expiresOn, mondayToken);
            if (!saveResult || !saveResult.ok) {
                showErrorNotification("SharePoint user data error");
                handleError();
                return;
            }
            console.log("Authentication is OK");

            router.push(`/`);
        } catch (error) {
            showErrorNotification();
            console.error("Handle auth error", error);
            handleError();
        }
    }

// error handler
    function handleError() {
        setIsToast(true);

        setTimeout(() => {
            setIsToast(false);
        }, 3000);
    }

    return (
        <Box backgroundColor={Box.backgroundColors.PRIMARY_BACKGROUND_COLOR} className={`${theme} text-center`} rounded={Box.roundeds.MEDIUM}
             shadow={Box.shadows.SMALL} padding={Box.paddings.XXXL}>

            <Heading color={Heading.colors.PRIMARY} type={Heading.types.H3}>
                {!isToast ?
                    "Please, authenticate with SharePoint." :
                    "Failed to authenticate. Please try again."
                }
            </Heading>

            {typeof window !== "undefined" ?
                <Button className="mt-6" onClick={handleAuthentication}>
                    Sign in
                </Button>
                : null
            }
        </Box>
    );
}
