import { graphURL, scopes } from "@/app/constants";
import { showErrorNotification } from "@/app/_util/notificator";

// region SharePoint sing in func
export async function spSignIn(userId, mondayToken, msalInstance) {
    const loginRequest = { scopes, prompt: "select_account" };

    try {
        const response = await msalInstance.loginPopup(loginRequest);
        console.log("SharePoint auth successful:", response);
        return response;
    } catch (error) {
        console.log("An error occurred during the SharePoint login process:", error);
        showErrorNotification("An error occurred during the SharePoint login process. Please try again.");
        return null;
    }
}

// endregion SharePoint sing in func

// get files preview
export async function getSPFilePreview(selectedFile, selectedSite, spToken) {
    if (!selectedFile || !selectedSite || !spToken) {
        return null;
    }
    const url = `${graphURL}/sites/${selectedSite}/drive/items/${selectedFile}/thumbnails`;
    const options = {
        method : "GET",
        headers: {
            "Authorization": "Bearer " + spToken,
            "Accept"       : "application/json",
            "Content-Type" : "application/json"
        }
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (!response.ok) {
            console.log("Fetching sites error, status:", data);
            return null;
        }

        return data?.value[0]?.large?.url;
    } catch (error) {
        console.error("Error fetch document preview: ", error);
        return null;
    }
}
